@font-face { font-family: Gotham Rounded; src: url('../fonts/gotham/gotham-rounded-medium.otf'); } 
@font-face { font-family: Gotham Rounded; font-weight: bold; src: url('../fonts/gotham/GothamRnd-Bold.otf');}
@font-face { font-family: Gotham Rounded; font-weight: lighter; src: url('../fonts/gotham/GothamRnd-Light.otf');}

body {
  background-color: #f5f5f5 !important;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: 'Gotham Rounded', 'Roboto', sans-serif !important;
}

.page-container {
  min-height: 100vh;
  padding-left: 250px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media screen and (min-width: 992px) {
  .page-container {
    padding-left: 70px;
  }
}
@media screen and (max-width: 991px) {
  .page-container {
    padding-left: 0;
  }
}
.main-content {
  padding: 198px 40px 20px 220px;
  min-height: calc(100vh - 0px);
}
@media screen and (max-width: 991px) {
  .main-content {
    padding: 198px 10px 10px 10px;
  }
}
@media screen and (max-width: 991px) {
  .full-container {
    left: 0;
  }
}
.is-collapsed .page-container {
  padding-left: 70px;
}
@media screen and (max-width: 991px) {
  .is-collapsed .page-container {
    padding-left: 0;
  }
}
@media screen and (min-width: 992px) {
  .is-collapsed .page-container {
    padding-left: 250px;
  }
}
.bgc-grey-100, .bgcH-grey-100:hover {
  background-color: #f9fafb!important;
}

/**********HEADER************/
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  min-width: 10rem;
  padding: 8px 0;
  padding: .5rem 0;
  margin: 2px 0 0;
  margin: .125rem 0 0;
  font-size: 14px;
  font-size: .875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}
.dropdown-menu.show {
  display: block;
}
.collapsing, .dropdown, .dropup {
  position: relative;
}
.navbar, .navbar>.container, .navbar>.container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.peers {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex!important;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.peer {
  display: block;
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.fxw-nw {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.lh-1 {
  line-height: 1!important;
}
.mR-10 {
  margin-right: 10px!important;
}
img {
  vertical-align: middle;
  border-style: none;
}
.sz-2r, .w-2r {
  width: 32px;
  width: 2rem;
}
.bdrs-50p {
  border-radius: 50%!important;
}
.peer>img {
  max-width: none;
}
.header {
  background-color: #25d07b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  display: block;
  margin-bottom: 0;
  padding: 0;
  position: fixed;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: calc(100% - 250px);
  z-index: 800;
  height: 178px;
}
@media screen and (max-width: 991px) {
  .header {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .header {
    width: calc(100% - 70px);
  }
}
.header .header-container:after {
  display: block;
  clear: both;
  content: "";
}
.header .header-container .nav-left,
.header .header-container .nav-right {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  position: relative;
}
.header .header-container .nav-left > li,
.header .header-container .nav-right > li {
  float: left;
}
.header .header-container .nav-left > li > a,
.header .header-container .nav-right > li > a {
  color: #fff;
  display: block;
  line-height: 55px;
  min-height: 55px;
  padding: 0 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.header .header-container .nav-left > li > a i,
.header .header-container .nav-right > li > a i {
  font-size: 17px;
}
.header .header-container .nav-left > li > a:focus,
.header .header-container .nav-left > li > a:hover,
.header .header-container .nav-right > li > a:focus,
.header .header-container .nav-right > li > a:hover {
  color: #ddd;
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .header .header-container .nav-left > li > a,
  .header .header-container .nav-right > li > a {
    padding: 0 15px;
  }
}
.header .header-container .nav-left .notifications,
.header .header-container .nav-right .notifications {
  position: relative;
}
.header .header-container .nav-left .notifications .counter,
.header .header-container .nav-right .notifications .counter {
  background-color: #ff3c7e;
  border-radius: 50px;
  color: #fff;
  font-size: 10px;
  line-height: 1;
  padding: 3px 5.5px;
  position: absolute;
  right: 6px;
  top: 12px;
}
.header .header-container .nav-left .notifications .dropdown-menu,
.header .header-container .nav-right .notifications .dropdown-menu {
  min-width: 350px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .header .header-container .nav-left .notifications .dropdown-menu,
  .header .header-container .nav-right .notifications .dropdown-menu {
    max-width: 300px;
  }
}
.header .header-container .dropdown-menu {
  display: block;
  margin: 0;
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: -webkit-transform 0.15s ease-out;
  -o-transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out,
    -webkit-transform 0.15s ease-out;
}
.header .header-container .dropdown-menu .divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  height: 1px;
  overflow: hidden;
}
.header .header-container .dropdown-menu > li > a {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.header .header-container .show {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.header .header-container .nav-left {
  float: left;
  margin-left: 15px;
}
.header .header-container .nav-right {
  float: right;
}
.header .header-container .nav-right .dropdown-menu {
  left: auto;
  right: 0;
}
.header .header-container .nav-right .dropdown-menu > li {
  width: 100%;
}
.header .header-container .nav-right .dropdown-menu > li > a {
  line-height: 1.5;
  min-height: auto;
  padding: 10px 15px;
}
.header .search-box.active .search-icon,
.header .search-box .search-icon-close {
  display: none;
}
.header .search-box.active .search-icon-close {
  display: inline-block;
}
.header .search-input {
  display: none;
}
.header .search-input.active {
  display: inline-block;
}
.header .search-input input {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
  height: 40px;
  margin-top: 12px;
  outline: none;
  padding: 5px;
}
@media screen and (max-width: 767px) {
  .header .search-input input {
    width: 85px;
  }
}
.header .search-input input::-webkit-input-placeholder {
  color: #a6aaac;
  font-style: italic;
}
.header .search-input input:-moz-placeholder,
.header .search-input input::-moz-placeholder {
  color: #a6aaac;
  font-style: italic;
}
.header .search-input input:-ms-input-placeholder {
  color: #a6aaac;
  font-style: italic;
}
.is-collapsed .header {
  width: calc(100% - 70px);
}
@media screen and (max-width: 991px) {
  .is-collapsed .header {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .is-collapsed .header {
    width: calc(100% - 250px);
    left: 250px;
  }
}
.fsz-sm {
  font-size: 13.92px!important;
  font-size: .87rem!important;
}
.c-grey-700, .cH-grey-700:hover {
  color: #72777a!important;
}
.c-grey-900, .cH-grey-900:hover {
  color: #313435!important;
}
.bgc-grey-100, .bgcH-grey-100:hover {
  background-color: #f9fafb!important;
}
.d-b {
  display: block!important;
}
.pY-5 {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}
.td-n {
  text-decoration: none!important;
}
@media print {
  .header, .navbar{
    display:none;
  }
}
.navbar-content-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navbar-title {
  color: #fff;
  font-size: 1.2rem;
}
.w2-5 {
  width: 1.5rem;
}
/**********HEADER************/

/**********SIDEBAR************/
.lh-1 {
  line-height: 1!important;
}
.mB-0 {
  margin-bottom: 0!important;
}
.sidebar {
  background-color: #fff;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 250px;
  z-index: 1000;
}
.sidebar ul {
  list-style-type: none;
}
@media screen and (min-width: 992px) {
  .sidebar {
    width: 70px;
  }
  .sidebar .sidebar-inner .sidebar-logo {
    border-bottom: 1px solid transparent;
    padding: 0;
  }
  .sidebar .sidebar-inner .sidebar-logo a .logo {
    background-position: 50%;
    width: 70px;
  }
  .sidebar .sidebar-inner .sidebar-menu {
    overflow-x: hidden;
  }
  .sidebar .sidebar-inner .sidebar-menu > li > a .title-sb {
    display: none;
  }
  .sidebar .sidebar-inner .sidebar-menu li.dropdown .arrow {
    opacity: 0;
  }
  .sidebar .sidebar-inner .sidebar-menu li.dropdown.open ul.dropdown-menu {
    display: none !important;
  }
  .sidebar:hover {
    width: 250px;
  }
  .sidebar:hover .sidebar-inner .sidebar-logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 0 20px;
  }
  .sidebar:hover .sidebar-inner .sidebar-menu > li > a .title-sb {
    display: inline-block;
  }
  .sidebar:hover .sidebar-inner .sidebar-menu li.dropdown .arrow {
    opacity: 1;
  }
  .sidebar:hover .sidebar-inner .sidebar-menu li.open > ul.dropdown-menu {
    display: block !important;
  }
}
@media screen and (max-width: 991px) {
  .sidebar {
    left: -250px;
    width: 250px;
  }
}
@media print {
  .sidebar{
    display:none;
  }
}
.sidebar-inner {
  position: relative;
  height: 100%;
}
.sidebar-logo {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
  padding: 0 20px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sidebar-logo a {
  display: inline-block;
  width: 100%;
}
.sidebar-logo a .logo {
  background-position: 0;
  background-repeat: no-repeat;
  display: inline-block;
  min-height: 64px;
  width: 100%;
  width: 70px;
}
.sidebar-logo a .logo-text {
  color: #313435;
  letter-spacing: .5px;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0;
}
.sidebar-logo .mobile-toggle {
  display: none;
  float: right;
  font-size: 18px;
  line-height: 64px;
}
.sidebar-logo .mobile-toggle a {
  color: #72777a;
}
@media screen and (max-width: 991px) {
  .sidebar-logo .mobile-toggle {
    display: inline-block;
  }
}
@media screen and (min-width: 992px) {
  .sidebar-logo .mobile-toggle {
    display: none;
  }
}
.sidebar-menu {
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
  height: calc(100vh - 182px);
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
}
.sidebar-menu:after {
  display: block;
  clear: both;
  content: "";
}
.sidebar-menu .dropdown-toggle:after {
  display: none;
}
/*.sidebar-menu .sidebar-link.active:before {
  background: #25d07b;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  left: -4px;
  position: absolute;
  top: calc(50% - 4px);
  width: 8px;
}*/
.sidebar-menu li {
  position: relative;
}
.sidebar-menu li.dropdown .arrow {
  font-size: 10px;
  line-height: 40px;
  position: absolute;
  right: 30px;
  -webkit-transition: all 0.05s ease-in;
  -o-transition: all 0.05s ease-in;
  transition: all 0.05s ease-in;
}
@media screen and (max-width: 991px) {
  .sidebar-menu li.dropdown .arrow {
    right: 25px;
  }
}
.sidebar-menu li.dropdown.open > a {
  color: #313435;
}
.sidebar-menu li.dropdown.open > a .icon-holder {
  color: #25d07b;
}
.sidebar-menu li.dropdown.open > a > .arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu li.dropdown.open > .dropdown-menu {
  display: block;
}
.sidebar-menu li.dropdown.open > .dropdown-menu .dropdown-menu {
  padding-left: 20px;
}
.sidebar-menu li.dropdown.open > .dropdown-menu .arrow {
  line-height: 25px;
}
.sidebar-menu li a {
  color: #999;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar-menu li a:focus,
.sidebar-menu li a:hover {
  color: #313435;
  text-decoration: none;
}
.sidebar-menu li a:focus .icon-holder,
.sidebar-menu li a:hover .icon-holder {
  color: #25d07b;
}
.sidebar-menu > li.dropdown ul.dropdown-menu {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: none;
  padding-left: 50px;
  padding-top: 0;
  position: relative;
  width: 100%;
}
.sidebar-menu > li.dropdown ul.dropdown-menu > li > a {
  display: block;
  padding: 10px 15px;
}
.sidebar-menu > li.dropdown ul.dropdown-menu > li > a:focus,
.sidebar-menu
> li.dropdown
ul.dropdown-menu
> li
> a:hover {
  background-color: transparent;
  color: #313435;
}
.sidebar-menu > li.dropdown ul.dropdown-menu > li.active a {
  color: #25d07b;
  font-weight: bold;
}
.sidebar-menu > li > a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 15px;
  position: relative;
  white-space: nowrap;
}
.sidebar-menu > li > a .icon-holder {
  border-radius: 6px;
  display: inline-block;
  font-size: 14px;
  height: 35px;
  left: 0;
  line-height: 35px;
  margin-right: 14px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 35px;
}
.sidebar-menu > li > a .icon-holder img {
  max-width: 15px;
}
@media screen and (min-width: 1440px) {
  .is-collapsed .sidebar {
    width: 70px;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-logo {
    border-bottom: 1px solid transparent;
    padding: 0;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu {
    overflow-x: hidden;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu > li > a .title-sb {
    display: none;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.dropdown .arrow {
    opacity: 0;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.dropdown.open ul.dropdown-menu {
    display: none !important;
  }
  .is-collapsed .sidebar:hover {
    width: 250px;
  }
  .is-collapsed .sidebar:hover .sidebar-inner .sidebar-logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 0 20px;
  }
  .is-collapsed .sidebar:hover .sidebar-inner .sidebar-menu > li > a .title-sb {
    display: inline-block;
  }
  .is-collapsed .sidebar:hover .sidebar-inner .sidebar-menu li.dropdown .arrow {
    opacity: 1;
  }
  .is-collapsed .sidebar:hover .sidebar-inner .sidebar-menu li.open > ul.dropdown-menu {
    display: block !important;
  }
}
@media screen and (min-width: 992px) {
  .is-collapsed .sidebar {
    width: 250px;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 0 20px;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-logo > a .logo {
    background-position: 0;
    width: 150px;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu > li > a .title-sb {
    display: inline-block;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.dropdown .arrow {
    opacity: 1;
  }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.open > ul.dropdown-menu {
    display: block !important;
  }
}
@media screen and (max-width: 991px) {
  .is-collapsed .sidebar {
    left: 0;
  }
}
.peers {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex!important;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.fxw-nw {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.peer {
  display: block;
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.peer-greed, .peers-greed>.peer, .peers-greed>.peers {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.td-n {
  text-decoration: none!important;
}
.pos-r {
  position: relative!important;
}
.ps {
  overflow: hidden!important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.mT-30 {
  margin-top: 30px!important;
}
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
.profile-picture {
  border-radius: 32px;
  margin: 5px 0 5px 0;
}
.userName {
  font-size: 1.3rem;
}
.companyName {
  font-size: 0.8rem;
  text-align: center;
}
.active span {
  font-weight: bold;
  color: #25d07b;
}
.sidebar-link {
  text-decoration: none;
}
/**********SIDEBAR************/

/*****************************/
/**********GENERAL************/
.no-margin {
  margin: 0;
}
.m1 {
  margin: 1rem;
}
.mt05 {
  margin-top: 0.5rem;
}
.mt1 {
  margin-top: 1rem;
}
.mt1-2 { margin-top: 1.2rem; }
.mt1-5 { margin-top: 1.5rem; }
.mt2 {
  margin-top: 2rem;
}
.mt3 {
  margin-top: 3rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mb3 {
  margin-bottom: 3rem;
}
.ml1 {
  margin-left: 1rem;
}
.ml2 {
  margin-left: 2rem;
}
.ml3 {
  margin-left: 3rem;
}
.m2 {
  margin: 2rem;
}
.mh05 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mh1 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mh2 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.pb1 {
  padding-bottom: 1rem;
}
.pr0-5 { padding-right: 0.5rem; }
.pr1 { padding-right: 1rem; }
.pr1-5 { padding-right: 1.5rem; }
.pr2 { padding-right: 2rem; }
.pr2-5 { padding-right: 2.5rem; }
.pr3 { padding-right: 3rem; }
.pv0 { padding-top: 0px; padding-bottom: 0px; }
.pv0-5 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.pv1 { padding-top: 1rem; padding-bottom: 1rem; }
.pv1-5 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.pv2 { padding-top: 2rem; padding-bottom: 2rem; }
.fsz-0-5 { font-size: 0.5rem; }
.fsz-1 { font-size: 1rem; }
.fsz-1-1 { font-size: 1.1rem; }
.fsz-1-2 { font-size: 1.2rem; }
.fsz-1-5 { font-size: 1.5rem; }
.fsz-2 { font-size: 2rem; }
.fsz-2-5 { font-size: 2.5rem; }
.fsz-3 { font-size: 3rem; }

.lh1 {
  line-height: 1;
}

/*****Card Component*****/
.card {
  background-color: #FFF;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  margin-bottom: 1rem;
}
.border {
  border: 1px solid #f5f5f5;
  width: 100%;
  height: 100%;
}
.withOutBorder {
  width: 100%;
  height: 100%;
}
.contenido {
  height: 100%;
}
.titulo {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  padding: 0.3rem 0;
  margin-bottom: 1rem;
}
.title {
  color: #25d07b;
  font-weight: bold;
}
.icon-card {
  color: #999;
}
/*****Card Component*****/

/*****InfoRow Component*****/
.info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
}
.info-row .label {
  font-size: 0.8rem;
  color: #333;
  text-align: right;
  padding-right: 0.2rem;
  flex: 0 0 40%;
  max-width: 40%;
}
.info-row .value {
  font-size: 0.8rem;
  color: #25d07b;
  text-align: left;
  padding-left: 0.2rem;
  font-weight: 600;
  flex: 0 0 60%;
  max-width: 60%;
}
/*****InfoRow Component*****/

/*****Login*****/
.login-bg {
  background-image: url("../images/login/login_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
.logo-login {
  float: left;
  width: 70px;
  margin-top: 1rem;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.login-box .titulo-box {
  background-color: #25d07b;
  color: #fff;
  padding: 0.1rem 1rem;
  font-size: 1.3rem;
  margin-bottom: 0;
  text-align: left;
}
.login-box .contenido-box {
  background-color: rgba(102,102,102, 0.5);
  color: #fff;
  padding: 1.5rem;
}
.login-box .contenido-box .col-1 {
  padding: 0px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.login-box .contenido-box .col-1 img {
  width: 60%;
}
/*****Login*****/

.div-titulo-seccion {
  margin-left: 0.5rem;
}
.icono-titulo-seccion {
  margin-top: 0.5rem;
}
.titulo-seccion {
  color: #25d07b;
  font-weight: 600;
}
.subtitulo-seccion {
  color: #333;
  font-size: 0.8rem;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flex-column-end-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.border-top-green {
  border-top: #25d07b solid 0.1rem;
}
.border-bottom-green {
  padding-bottom: 1rem;
  border-bottom: #25d07b solid 0.1rem;
}

.titulo-servicio {
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
}

.square-service {
  width: 100%;
  height: 100px;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}
/* .square-service:after {
  content: "";
  display: block;
  padding-bottom: 100%;
} */
.square-service .thumbnail {
  flex: 3;
  align-items: center;
  justify-content: center;
  display: flex;
}
.square-service img {
  max-height: 100%;
}
.square-service b {
  flex: 2;
  font-size: 0.7rem;
  color: #333;
  text-align: center
}

.ctbd-div-titulo {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ctbd-titulo {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.rangeslider-horizontal {
  border-radius: 0px !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #25d07b !important;
  border-radius: 0px !important;
}
.rangeslider__label-item {
  color: #25d07b;
}

.flatpickr-months .flatpickr-month {
  background: #25d07b !important;
}
.flatpickr-weekdays {
  background: #25d07b !important;
}
span.flatpickr-weekday {
  background: #25d07b !important;
}

.input-monto {
  padding: 0.9rem;
}
.input-monto input {
  background-color: transparent;
  border: 0px;
  font-weight: bold;
  font-size: 2rem;
  padding: 0px;
  height: auto;
}
.verde {
  color: #25d07b;
}
.rojo {
  color: #ff6b6b;
}

.profileImageDiv {
  position:relative;
  display:inline-block;
}
.overlay {
  display: none;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 32px;
  margin: 5px 0 5px 0;
  width: 64px;
  height: 64px;
  padding:12px;
}
.profileImageDiv:hover .overlay {
  width:100%;
  height:100%;
  background:rgba(0,0,0,.5);
  position:absolute;
  top:0;
  left:0;
  display:inline-block;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
  text-align:center;
  color:white;
  border-radius: 32px;
  margin: 5px 0 5px 0;
  width: 64px;
  height: 64px;
  padding: 17px 0px;
}
.profileImageDiv label {
  cursor: pointer;
}


/**********GENERAL************/
/*****************************/



/************* Fau *************/
#sidebar-menu li a.sidebar-link {
  color: #000;
}

#sidebar-menu li a.sidebar-link:hover{
  color: #25d07b;
}

hr.white-hr {
  border-color: #fff;
}

.btn-green-dashborad {
  font-size: 0.9rem;
}

.row-mt-15 {
  margin-top: 15px;
}

.form-control:focus {
  border-color: #68D17B !important;
  box-shadow: 0 0 5px rgba(104, 209, 123, 0.5) !important;
}

.fsz-0-8 {
  font-size: 0.8rem;
}

/************ End Fau **********/
